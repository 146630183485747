import React from "react"
import styled from "styled-components";
import ContactForm from "../../components/landing/ContactForm";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import background from "../../images/source/fondo_ventsup.png"
import logo from "../../images/source/logo_ventsupport.png"
import background_small from "../../images/source/fondo_ventsup_small.png"

const VentSupport = ({ location }) => (
  <Layout location={location}>
    <SEO title="VentSupport" />
    <Styled>
      <div className="info">
        <img src={logo} alt="logo" height='47px' width='346px' />
        <h3>Soporte inteligente para la ventilación mecánica</h3>
        <p>Cuida la vida con inteligencia.</p>
        <iframe width="350" height="196" src="https://www.youtube.com/embed/6wetYySpBcw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <div className="background">
          <div className="text">
            <ul>
              <li>Facilita el monitoreo y la interpretación clínica de pacientes ventilados, gracias a su algoritmo de IA.</li>
              <li>Alerta, predice y clasifica asincronías, a partir de machine learning.</li>
              <li>Ayuda al profesional de salud a lograr un mejor trabajo de respiración en el paciente.</li>
              <li>Monitoreo 24x7.</li>
              <li>Sincroniza a Cloud, acceso por web y alertas al móvil.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contact">
        <ContactForm page="VentSupport" location={location} title={<span>Contáctanos para conocer <b>VentSupport</b> y las experiencias de aplicación.</span>} paddingTitle='0px 25px' />
      </div>
    </Styled>
  </Layout>
)

const Styled = styled.div`
height: 100%;
min-height: 790px;
padding-top: 60px;
display:flex;
background-image: url("${background}");
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
background-size: cover;
background-position-y: -40px;
background-position-x: 0px;
background-repeat-y: no-repeat;


@media (max-width: 1100px) {
  background-position-y: -40px;
  background-position-x: -355px !important;
}

@media (max-width: 1300px) {
  background-position-y:  -40px;
  background-position-x:-355px !important;
}

@media (max-width: 1600px) {
  background-position-y:  -40px;
  background-position-x:-185px;
}

@media (max-width: 780px) {
  flex-direction: column-reverse;
  background: none;
  padding-top: 0px;

}

 .info {

    margin-top: 60px;
    @media (max-width: 780px) {
      margin-top: 20px;
    }

    img {
      margin-bottom: 0px;
    }

    h3 {
        height: 17px;
        width: 350px;
        color: #000000;
        font-size: 13.92px;
        font-weight: bold;
        letter-spacing: 0.38px;
        line-height: 17px;
    }

    p {
      height: 20px;
      width: 329px;
      color: #007881;
      font-family: "Century Gothic";
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    iframe {
      margin-bottom: 15px;

      @media (max-width: 780px) {
        width: 375px;
        height: 220px;
        margin-bottom: 0px !important;      
      }
    }
    
    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text {
        margin-top: 15px;
        height: 192px;
        width: 350px;
        color: #FFFFFF;
        font-family: "Century Gothic";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;

        ul {

          list-style: none;
          position: relative;
          left: -20px;

          li {
            width: 350px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;

            &:before {
              content: "•";
              color: #E3DE1E;
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
          
        }

                
        @media (max-width: 780px) {
          margin: 0 auto;
          padding-top: 20px;
          height: 350px;
        }
    }

    .background {
      @media (max-width: 780px) {
            width: 100%;
            background-image: url("${background_small}");
        }
    }

 }

 .contact {
    margin-top: 70px;
    padding: 40px;
    height: 588px;
    width: 573px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    margin-left: 110px;

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      box-shadow: none;
      border-radius: 0;
      margin-top: 0px;
      padding: 20px;
      width: 100%;
    }
 }

`


export default VentSupport;